.star-button {
    position: relative;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: transparent;
    border: 2px solid #fff;
    border-radius: 5px;
    cursor: pointer;
    overflow: hidden;
    margin-top: 2vh;
}

.star-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"%3E%3Cpolygon points="50,15 61,35 82,38 67,54 72,75 50,65 28,75 33,54 18,38 39,35" fill="white"%3E%3C/polygon%3E%3C/svg%3E') repeat;
    background-size: 10px 10px;
    /* Adjust size of the stars */
    opacity: 0.5;
    /* Adjust star visibility */
    z-index: -1;
}

.star-button:hover::before {
    opacity: 1;
}

.star-button:active {
    background-color: #333;
}

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2%;
}

.image {
    position: absolute;
    top: -10vh;
    background-color: black;
    border: 0.3vh solid #fff;
    border-radius: 2%;
    width: 10vh;
    height: 15vh;
    z-index: 1;
}

.payment-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #333;
    opacity: 0.9;
    padding: 2%;
    border: 0.3vh solid #fff;
    border-radius: 2%;
    position: relative;
    z-index: 0;
}

.headline {
    font-size: 2em;
    text-align: center;
    margin-top: 6vh;
    margin-bottom: 3vh;
    border-bottom: 0.5vh solid #fff;
    color: white;
}

.payment-form-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .payment-form h2 {
    margin-bottom: 20px;
    font-size: 24px;
    text-align: center;
  }
  
  .payment-form label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .payment-form input {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .payment-form button {
    width: 100%;
    padding: 12px;
    background-color: #0070f3;
    color: white;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .payment-form button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .error {
    color: red;
    margin-top: 10px;
  }

  .success-loading-div {
    height: 79vh; margin: 0; padding: 0;display: flex;justify-content: center;align-items: center;
  }

  .success-Loadign-first {
    color: blue; font-weight: bold; font-family: Tahoma, sans-serif; 
  }


  
@media (max-width: 500px) {
  .imageReceipt {
    width: 340px !important
  }

  .imageConReceipt {
    min-height:550px !important
  }
}