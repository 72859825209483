.camera-container {
    position: relative;
    width: 40vh;
    height: 40vh;
    overflow: hidden;
    /* Ensure no overflow from the container */
}

.round-image {
    width: 40vh;
    /* Adjust as needed */
    height: 40vh;
    /* Adjust as needed */
    border-radius: 100%;
    /* Makes the image round */
    object-fit: cover;
    /* Ensures the image covers the entire container without distortion */
    border: none;
    /* Optional: Adds a border around the image */
    clip-path: polygon(50% 0px, 100% 50%, 50% 100%, 0px 50%);

}

.overlay-image {
    position: 'absolute';
    z-index: 1;
    content: center;
    margin-left: -1.1%;
    margin-top: -0.7%;
    height: 40vh;
    width: 40vh;
    clip-path: polygon(50% 0px, 100% 50%, 50% 100%, 0px 50%);
}

.capture-button {
    background-color: #007BFF;
    /* Blue color */
    color: #FFFFFF;
    /* White text color */
    border: none;
    padding: 10px 20px;
    /* Padding for top-bottom and left-right */
    border-radius: 5px;
    /* Rounded corners */
    font-size: 16px;
    /* Font size */
    cursor: pointer;
    /* Hand cursor on hover */
    transition: background-color 0.3s;
    /* Smooth transition for hover effect */
    margin: 20px auto;
    /* Margin for spacing and auto for centering */
    display: block;
    /* To make the button a block element for centering */
}

.capture-button:hover {
    background-color: #0056b3;
    /* Darker blue color on hover */
}