.print-details {
  display: flex;
  padding: 10px 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.print-details h1 {
  font-size: 16px;
  margin: 0;
  padding: 1px;
}

.first-para {
  font-size: 12px;
  padding: 0;
  text-decoration: underline;
}

img {
  padding: 3px;
  height: 150px;
  width: 130px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
}

.name {
  margin: 5px;
  background-color: gray;
  padding: 0;
}

.code {
  margin: 0;
  background-color: gray;
  padding: 0;
}

.line {
  width: 60vw;
  border-top: 1px solid #000; /* Adjust the color and thickness as needed */
  margin: 0;
  padding: 0;
}

.details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50vw;
  padding: 0;
}

.details p {
  font-weight: 600;
  font-size: 16px;
  padding: 0;
  text-decoration: underline;
  margin: 0;
}

.details ul,
.third-para ul {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 13px;
}

.third-para {
  width: 50vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 5px;
}

.third-para h3 {
  text-decoration: underline;
  margin: 0;
  padding: 0;
}

.third-para ul li {
  text-decoration: underline;
}

.four-para {
  width: 50vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 5px;
}

.four-para h3 {
  text-decoration: underline;
  margin: 3px;
  padding: 0;
}

.four-para p {
  padding: 0;
  margin: 3px;
}

.five-para {
  width: 50vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-size: 13px;
  margin-bottom: 1px;
}

.last-para {
  width: 50vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.last-para a {
  font-size: 11px;
  padding: 2px;
  margin: 0;
}

.last-para h5 {
  font-size: 12px;
  padding: 1px;
  margin: 0;
}

.last-para p {
  font-size: 9px;
  padding: 2px;
  margin: 0;
}

.print-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.print-btn button {
  margin: 10px;
  padding: 5px 8px;
  background-color: inherit;
  color: #0000e2;
  border-radius: 3px;
  font-size: 18px;
}
