@font-face {
  font-family: "DIN Condensed";
  src: url('assets/fonts/DIN-Condensed-Bold.ttf');
}

@font-face {
  font-family: "Helvetica Neue";
  src: url('assets/fonts/HelveticaNeueMedium.otf');
}

@font-face {
  font-family: "Andale Mono";
  src: url('assets/fonts/1430-font.ttf');
}

@font-face {
  font-family: "Slayer";
  src: url('assets/fonts/slayer.TTF');
}

#root {
  height: 100%;
}

html {
  scroll-behavior: smooth;
}

.bible-quote-test {
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 13px;
  line-height: 21px;
  letter-spacing: 0.12ch;
  color: #7b7182;
  margin-bottom: -4px !important;
  display: none
}

.bible-quote {
  font-family: 'Slayer', sans-serif;
  font-size: 13px;
  line-height: 21px;
  letter-spacing: 0.12ch;
  color: #7b7182;
  margin-bottom: -4px !important;
}

.bible-source {
  font-family: Tahoma, 'Segoe UI', Verdana, sans-serif;
  position: relative;
  letter-spacing: 0;
  color: #7b7182;
}

body {
  font-family: 'DIN Condensed', sans-serif;
  background-color: #002bff;
  letter-spacing: 0.4ch;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  text-align: justify;
}

.app {
  margin: 0;
  padding: 0;
}

img {
  height: 17rem;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-imge {
  border: none;
  height: 20%;
  width: 20%;
}

.img-btn {
  margin: 10px;
  padding: 0;
  border: none;
  background-color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5vh !important;
  width: 5vw;
}

.img-btn img {
  opacity: 0.7;
  height: 50px;
  width: 70px;
  border: none;
  margin: 10px;
}

.autoplay-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.timer-container {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* gap: 2rem; */
}

.count-down {
  color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.days {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #002bff;
  padding: 0;
  margin: 0;
}

.days h2 {
  font-size: 26px;
  padding: 0 10px;
  margin: 40px 0 0 0;
}

ul {
  display: flex;
  list-style: none;
  font-size: 5vw;
  height: 13vh;
  color: #002bff;
  padding: 0;
  margin: 0;
  justify-content: center;
  align-items: center;
}

.image-gallery {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 1.6rem;
}

.image-text1 {
  height: auto;
  width: 95vw;
  border: none;
}

.share-page {
  color: white;
}

.share-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.time-remain {
  font-size: 48px;
  font-weight: bold;
  color: #002bff;
  padding: 0;
  text-align: center;
}

.timer-name {
  color: #002bff;
  font-size: 20px;
  margin-bottom: 20;
  padding: 0 !important;
  text-align: center;
  text-decoration: underline;
}

.share-links {
  display: flex;
  flex-direction: row;
  gap: 1.3rem;
}

.icone {
  margin-top: 2rem;
}

.live-budget {
  color: #002bff;
  text-align: center;
  margin-bottom: 40px;
}

.live-budget h2 {
  color: #002bff;
  text-decoration: none;
  font-size: 40px;
  font-weight: bolder;
  margin-bottom: -40px;
}

.para {
  display: flex;
  justify-content: center;
  align-items: center;
}

.para p {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #002bff;
  font-size: 20px;
  font-weight: 300;
  margin: 10vh 10px;
}

.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10% 0;
}


.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contact-form-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 60px 50px;
}
.contact-overlay-success {
  border: 5px solid;
    border-image: linear-gradient(50deg, #02a2ff, white, #02a2ff, white, #02a2ff) 1;
  width: 300px;
    height: 300px;
  /* width: 100vw;
  height: 100vh; */
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* Ensure positioning for inner elements */
  overflow: hidden; /* Prevent scrolling if content overflows */
}

.contact-overlay-success::before {
  content: ""; /* Add pseudo-element for the background GIF */
  position: absolute;
  width: 300px; /* Set the fixed width for the background GIF */
  height: 300px; /* Set the fixed height for the background GIF */
  background-image: url('./components/images/shooting-star.gif');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transform: rotate(270deg); /* Rotate only the background */
  transform-origin: center;
  z-index: 0; /* Ensure it's behind the content */
}

.heading {
  text-align: center;
  position: absolute;
  bottom: 20px; /* Position the text slightly above the bottom */
  right: 20px; /* Position the text slightly left of the right edge */
  color: white; /* Set the text color for contrast */
  font-size: 24px; /* Adjust font size as needed */
  text-decoration: none; /* Remove any text decoration */
  z-index: 1; /* Ensure it's above the background GIF */
}

.heading h2 {
  margin: 0;
  z-index: 1px;
  text-decoration: unset;
}


.contact-form input {
  text-align: center;
  color: lightgray;
}

.contact-form textarea {
  color: lightgray;
}

.contact-form .heading {
  margin-bottom: inherit;
  margin-top: inherit;
}

.contact-popup {
  /* background-image: url('./components/images/shooting-star.gif'); */
  border-radius: 20px;
  background-repeat: no-repeat;
  background-size: cover;
}

.form-inputs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.submit-button {
  height: 1.5rem;
  width: 5rem;
  border: none;
  border-radius: 2rem;
  color: white;
  background-color: #002bff;
  transition: background-color 0.3s, color 0.3s;
}

.submit-button:hover {
  background-color: #99c5e0;
  color: #fff;
}

.contact-icon {
  margin-top: 5%;
  color: #002bff;
  font-size: 28px;
  font-weight: 700;
  text-decoration: underline;
  margin-bottom: 1rem;
  cursor: pointer;
}

.input {
  width: 90%;
  padding: 7px 10px;
  border: none;
  border-radius: 3rem;
}

.input-area {
  border: none;
  width: 90%;
  padding: 2vh 1vw;
  border-radius: 1rem;
  background-color: #1329f0;
  color: white;
  text-align: center;
}

.cross-button {
  color: #ffff;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 1.2rem;
  margin-right: 1rem;
}

.cross-button svg {
  cursor: pointer;
}

.heading {
  color: white;
  font-size: 22px;
  letter-spacing: 0.5ch;
}

.contact-overlay {
  position: fixed;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.involvement {
  color: gainsboro;
}

.button-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 2vh;
  margin-left: 20vw;
  margin-bottom: 10vh;
}

.button-container h2 {
  color: white;
  font-weight: 200;
}

.buttons-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5rem;
}

.image-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.7rem;
}

.six-images {
  height: 7rem;
  width: 3.5rem;
}

.spacer {
  width: 50px;
  height: 50px;
}

.form-button-next {
  width: 50px !important;
  height: 50px !important;
  margin: 0 !important;
  padding: 0 !important;
  position: relative !important;
}

.button-names {
  height: 7vh;
  font-size: 2vw;
  font-weight: bold;
  border: none;
  text-decoration: underline;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 2vh;
  background-color: transparent;
  color: rgb(0, 43, 255);
}

.button-names img {
  height: 8vh;
  width: 5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1vh;
  background: inherit;
  opacity: 1;
  border: none;
}

.diff-container form .button-names {
  position: absolute;
  bottom: 0;
  height: unset;
}

.popup-content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("components/images/background1.jpg");
}

.button-names {
  cursor: pointer;
}

.sharepopup-content {
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 1000;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.social-media {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  gap: 13px;
}

.container-icone {
  display: flex;
  justify-content: flex-end;
}

.popup-close-icon {
  color: rgb(240, 240, 240);
  font-size: 24px;
  cursor: pointer;
  z-index: 9999;
}

.circular-image {
  width: 36px;
  height: 36px;
  border: none;
}

.share-social-icon {
  height: 200px;
  width: 200px;
  border: none;
  border-radius: 10px;
  padding: 0;
  transition: filter .4s ease-in-out;
  cursor: pointer;
}

.close-icon {
  position: fixed;
  top: 10px;
  right: 21px;
  font-size: 24px;
  cursor: pointer;
  z-index: 9999;
}

.webcam {
  height: 9rem;
  margin-top: 3rem;
}

.webcam-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.webcam-img {
  height: 50vh;
}

.main-container {
  width: 260px;
  height: 260px;
  background-color: white;
  border: 3px solid #002bff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.input-main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /*transform: translateY(50%);*/
  transform: translateY(30px);
}

.input-container .any {
  display: flex;
  flex-direction: column;
  /*justify-content: space-between;
  margin-top: 7rem;
  align-items: center;
  width: 100%;*/
}

input {
  color: white;
  background-color: #002bff;
}

.input-container input {
  margin-top: 10px;
  padding: 8px 5px;
  font-size: 18px;
  background-color: transparent;
  color: lightgray;
  border: 4px solid #002bff;
  border-radius: 5px;
  text-align: center;
}

.input-container input[type="text"]::placeholder,
.input-container input[type="email"]::placeholder {
  color: white;
}

.input-container .webcam {
  height: 25vh;
  border-radius: 5px;
  text-align: center;
}

.capture {
  height: 7rem;
  width: 2rem;
}

.colon {
  display: flex;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
}

.colon .span-colon {
  /* padding: 1vh 1.5vw; */
  font-size: 40px;
  font-weight: bold;
  display: flex;
  padding: 0;
  margin: 0;
}

.diff-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 80%;
}

.diff-container form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
}

.diff-container .button-names img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.diff-container ::before {
  content: "";
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform-style: preserve-3d;
  transform: rotate(0deg) translateZ(10em);
  z-index: -1;
}

.email-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  object-fit: cover;
  z-index: -1;
}

@media (max-width: 420px) {
  .timer-name {
    font-size: 12px !important;
    text-decoration: underline;
    color: #196aa0;
  }
}

@media (max-width: 368px) {
  .timer-name {
      font-size: 11px !important;;
  }
}

@media (max-width: 768px) {
  body {
    background-image: url('components/images/background1.jpg');
    background-size: cover;
    width: 100vw;
    max-height: 100%;
  }

  .share-container {
    height: 200px;
  }

  .live-budget {
    margin-bottom: 50px;
  }

  .live-budget h2 {
    margin-bottom: unset;
  }

  .sharepopup-content {
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
  }

  .container-icone {
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
  }

  .popup {
    position: fixed !important;
    top: 0 !important;
    left: 0;
  }

  .image-text1 {
    height: auto;
    width: 90vw;
  }

  .timer-name {
    font-size: 15px;
    text-decoration: underline;
    color: #196aa0;
  }

  /* .timer-container {
    gap: 10px;
  } */

  h2 {
    font-size: large;
    text-align: center;
  }

  .time-remain {
    /* margin-left: 6px; */
    font-size: 48px;
    padding: 0;
    margin: 0;
  }

  .contact-popup {
    width: 260px;
  }

  .input {
    width: 90%;
  }

  .webcam {
    height: 10rem;
  }

  .contact-overlay {
    height: 100%;
    width: 100%;
  }

  .popup-content {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button-names {
    width: 13rem;
  }

  .diff-container {
    display: flex;
    flex-direction: column;
    gap: 5.4rem;
  }

  .main-container {
    width: 180px;
    height: 180px;
  }

  .any input {
    width: 145px;
    margin-left: 0;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.play-button {
  background-color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.play-button:hover {
  background-color: #f0f0f0;
}

.success-message {
  font-size: 14px;
  color: white,
}

.error-message {
  font-size: 14px;
  color: white,
}

.content-container {
  display: flex;
  justify-content: space-between;
  /* This will space out your button and SVG containers */
  align-items: flex-start;
  /* This will align the containers to the top */
  padding: 20px;
  /* Adjust the padding as needed */
}

.svg-container {
  flex: 1;
  /* This allows the SVG container to take up the remaining space */
  display: flex;
  justify-content: center;
  /* Center the SVG horizontally */
  align-items: center;
  /* Center the SVG vertically */
  padding: 20px;
  /* Adjust as necessary for spacing */
}

.svg-image {
  max-width: 100%;
  /* Ensures the SVG doesn't exceed the container width */
  height: '100%';
  /* Maintains aspect ratio */
}

.section-container {
  max-width: 1003px;
  margin: 0 auto;
  width: 100%;
}
/* Styles for screens smaller than 768px */
@media screen and (max-width: 420px) {
  p {
    color: #ffffff;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.5px;
  }

  #mode-of-action ul {
    font-size: 14px;
  }

}

/* Container styling */
.autoplay-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Play Icon positioning */
.play-icon {
  position: absolute;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
  font-size: 40px;
  z-index: 10;
}

/* Play icon on hover */
.play-icon:hover {
  opacity: 0.8;
}

/* Video styling */
.video {
  border-radius: 8px;
}
